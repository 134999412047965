import { LoginResponse } from "../../../core/models/authentication";

const namespace = '[authentication]';

export const LOGIN = `${namespace} Login`;
export const LOGIN_SUCCESS = `${namespace} Login success`;
export const LOGIN_ERROR = `${namespace} Login failed`;
export const SHOW_LOADING = `${namespace} Show loading`;
export const HIDE_LOADING = `${namespace} Hide loading`;
export const SET_USERNAME = `${namespace} Set username`;
export const SET_PASSWORD = `${namespace} Set password`;
export const REDIRECT_TO = `${namespace} Redirect to`;
export const RESET_STATE = `${namespace} Reset auth state`;

export interface LoginActionType {
    type: typeof LOGIN,
    payload: {
        identifier: string;
        secret: string;
        returnUrl: any;
    }
}

export function login(identifier: string, secret: string, returnUrl: any): LoginActionType {
    return {
        type: LOGIN,
        payload: { identifier, secret, returnUrl }
    };
}

export function showLoading() {
    return {
        type: SHOW_LOADING
    };
}

export function hideLoading() {
    return {
        type: HIDE_LOADING
    };
}

export function setUsername(value: string) {
    return {
        type: SET_USERNAME,
        payload: value,
    };
}

export function setPassword(value: string) {
    return {
        type: SET_PASSWORD,
        payload: value,
    };
}

export function loginSuccess(response: LoginResponse, returnUrl: any) {
    return {
        type: LOGIN_SUCCESS,
        payload: response,
        meta: returnUrl,
    };
}

export function loginError(message: string, code: number) {
    return {
        type: LOGIN_ERROR,
        payload: {
            message,
            code,
        },
    };
}

export function redirectTo(path: string) {
    return {
        type: REDIRECT_TO,
        payload: path,
    };
}

export function resetState() {
    return {
        type: RESET_STATE
    };
}
