import { Middleware } from 'redux';

import { LOAD_USER, hideLoading, loadUserSuccess, showLoading } from './actions';
import { RoomService } from '../../../core/services/room';
import { RootState } from '../../../core/store/store';
import { toastr } from 'react-redux-toastr';

const loadUserMiddleware: Middleware<{}, RootState> = ({ dispatch, getState }) => next => action => {
    next(action);
    // const state = getState()
    if (action.type === LOAD_USER) {
        dispatch(showLoading());

        RoomService.loadUserRoomAccess()
            .then(response => {
                dispatch(loadUserSuccess(response.data))
                dispatch(hideLoading());
            })
            .catch(error => {
                toastr.error('Loading user access list failed', 'Incorrect credentials or server error!');
                dispatch(hideLoading());
            });
    }
}


export const userMiddleware = [loadUserMiddleware];