import { Middleware } from 'redux';

import { hideLoading, LOGIN, loginError, loginSuccess, LOGIN_SUCCESS, redirectTo, setPassword, showLoading } from './actions';
import { AuthService } from '../../../core/services/authentication';
import { RootState } from '../../../core/store/store';
import { toastr } from 'react-redux-toastr';

const loginFlow: Middleware<{}, RootState> = ({ dispatch, getState }) => next => action => {
    next(action);

    const state = getState()
    if (action.type === LOGIN) {
        dispatch(showLoading());
        AuthService.login(state.auth.username, state.auth.password)
            .then(response => {
                AuthService.setUsername(state.auth.username);
                dispatch(loginSuccess(response.data, action.payload?.returnUrl));
                dispatch(hideLoading());
            })
            .catch(error => {
                toastr.error('Login Failed', 'Incorrect credentials or server error!');
                dispatch(loginError('Could not login', error.status));
                dispatch(setPassword(''));
                dispatch(hideLoading());
            });
    }
}

const processLogin: Middleware<{}, RootState> = ({ dispatch }) => next => action => {
    if (action.type === LOGIN_SUCCESS) {
        AuthService.setToken(action.payload.access);
        AuthService.setRefreshToken(action.payload.refresh);
        toastr.success('Login Success', 'You have successfully logged in');
        dispatch(redirectTo(action.meta ? action.meta : '/' + AuthService.getUsername()))
    }

    next(action);
}

export const authMiddleware = [loginFlow, processLogin];