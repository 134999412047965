import { Middleware } from 'redux';

import { refreshTokenSuccess, REFRESH_TOKEN } from './actions';
import { AuthService } from '../services/authentication';
import history from '../services/history';
import { RootState } from './store';
import { toastr } from 'react-redux-toastr';


const refreshFlow: Middleware<{}, RootState> = ({ dispatch }) => next => action => {
    next(action);

    if (action.type === REFRESH_TOKEN) {
        AuthService.refresh(action.payload.refresh)
            .then(response => {
                AuthService.setToken(response.data.access);
                dispatch(refreshTokenSuccess(response.data))
            })
            .catch((error) => {
                toastr.error('Refresh failed', 'You need to login again.');
                console.log('refreshFlow failed', error)
                dispatch(refreshTokenSuccess(error))
                localStorage.clear();
                history.push('/account/login?return=' + history.location.pathname);
            });
    }
}

export const appMiddleware = [refreshFlow];