
import { Room } from '../../../core/models/room';
import { HIDE_LOADING, LOAD_USER_SUCCESS, RESET_STATE, SHOW_LOADING } from './actions';

export interface UserReducerState {
  loading: boolean;
  user: Room[];
}

const init: UserReducerState = {
  loading: false,
  user: null as any,
};

export function userReducer(state = init, action: any): UserReducerState {
  switch (action.type) {
    case SHOW_LOADING: return { ...state, loading: true };
    case HIDE_LOADING: return { ...state, loading: false };
    case LOAD_USER_SUCCESS: return {...state, user: action.payload};
    case RESET_STATE: return { ...init };
    default: return state;
  }
}