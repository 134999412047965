import { RoomAccess } from '../models/room';
import { EnvironmentService } from './environment';
import { HttpService } from './http';

const url = EnvironmentService.readConfig().apiBaseUrl + '/api'

export class RoomService {
    static changeState(roomid: number, state: string) {
        return HttpService.post(`${url}/v1/room/${roomid}/state/`, { state })
    }

    static loadRoom(id: number): Promise<any> {
        return HttpService.get(url + '/v1/room/' + id);
    }

    static loadUserRoomAccess(): Promise<any> {
        return HttpService.get(url + '/v1/user/');
    }

    static updateUserAccess(roomid: number, data: RoomAccess): Promise<any> {
        return HttpService.post(`${url}/v1/room/${roomid}/updateUserAccess/`, data);
    }

    static getRoomTriggers(roomid: number): Promise<any> {
        return HttpService.get(`${url}/v1/room/${roomid}/trigger`);
    }

}