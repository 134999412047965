export interface Environment {
  apiBaseUrl?: string;
  imageServerBaseUrl?: string;
  deviceServerBaseUrl?: string;
}

export class EnvironmentService {
  static readConfig(): Environment {
    return {
      apiBaseUrl: process.env.REACT_APP_SERVER_URL,
      imageServerBaseUrl: process.env.REACT_APP_SERVER_IMAGE_URL,
      deviceServerBaseUrl: process.env.REACT_APP_DEVICE_SERVER_URL,
    };
  }
}