import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { AuthService } from './core/services/authentication';
import { RootState, store } from './core/store/store';
import { refreshToken } from './core/store/actions';
import reportWebVitals from './reportWebVitals';
import '@material/react-snackbar/index.scss';
import ReduxToastr from 'react-redux-toastr';
import Navigator from './modules/navigator';

import './styles/main.scss';

function App() {
  const themeName = useSelector((root: RootState) => root.app.themeName);
  const dispatch = useDispatch();
  if (AuthService.getRefreshToken()) {
    dispatch(refreshToken(AuthService.getRefreshToken() || ''));
  }

  return (
    <div className={'theme-' + themeName}>
      <svg style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="blur">
            <feGaussianBlur stdDeviation="3" />
          </filter>
        </defs>
      </svg>
      <Navigator/>
    </div>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ReduxToastr
      newestOnTop={false}
      preventDuplicates
      transitionIn={'fadeIn'}
      transitionOut={'fadeOut'}
      position="bottom-right"
      closeOnToastrClick />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
