import { Middleware } from 'redux';

import { loadRoomSuccess, LOAD_ROOM, showLoading, hideLoading } from './actions';
import { RoomService } from '../../../core/services/room';
import { RootState } from '../../../core/store/store';

const loadRoomMiddleware: Middleware<{}, RootState> = ({ dispatch }) => next => action => {
    next(action);

    if (action.type === LOAD_ROOM) {
        dispatch(showLoading())
        RoomService.loadRoom(action.payload)
            .then(result => {
                dispatch(loadRoomSuccess(result.data))
                dispatch(hideLoading())
            });
    }
}

export const roomMiddleware = [loadRoomMiddleware];