import axios, { AxiosRequestConfig } from 'axios';
import { AuthService } from './authentication';
import history from '../services/history';

const client = axios.create({
    responseType: 'json',
});

client.interceptors.request.use(
    request => {
        if (AuthService.isAuthenticated()) {
            if (request && request.headers) {
                request.headers.Authorization = 'Bearer ' + AuthService.getToken();
            }
        }
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);


client.interceptors.response.use(response => {
    return response;
}, error => {

    const status = error.response ? error.response.status : null;
    if (status === 401 || status === 403) {
        // Handle unauthorized access
        AuthService.refresh(AuthService.getRefreshToken() ?? '')
            .then(response => {
                AuthService.setToken(response.data.access);
            })
            .catch((error) => {
                localStorage.clear();
                history.push('/account/login?return=' + history.location.pathname);
            });
        // Retry the original request
        return axios(error.config);
    } else if (status === 404) {
        // Handle not found errors
    } else {
        // Handle other errors
    }
    return Promise.reject(error)
})

export class HttpService {
    static get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return client.get(url, config);
    }

    static post<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
        return client.post(url, payload, config);
    }

    static put<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
        return client.put(url, payload, config);
    }

    static patch<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
        return client.patch(url, payload, config);
    }

    static del<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return client.delete(url, config);
    }
}
