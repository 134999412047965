import { LoginRequest, RefreshRequest } from '../models/authentication';
import { LocalStorageKeys } from '../models/local-storage';
import { EnvironmentService } from './environment';
import { HttpService } from './http';

const url = EnvironmentService.readConfig().apiBaseUrl + '/api'

export class AuthService {

    static login(identifier: string, secret: string): Promise<any> {
        const loginPayload: LoginRequest = {
            username: identifier,
            password: secret,
        };
        return HttpService.post(url + '/token/', loginPayload);
    }

    static refresh(refreshToken: string): Promise<any> {
        const loginPayload: RefreshRequest = {
            refresh: refreshToken
        };
        return HttpService.post(url + '/token/refresh/', loginPayload);
    }

    static getToken(): string {
        const res = localStorage.getItem(LocalStorageKeys.token);
        return res ? res : "";
    }

    static setToken(token: string) {
        localStorage.setItem(LocalStorageKeys.token, token);
    }

    static getRefreshToken() {
        return localStorage.getItem(LocalStorageKeys.refreshToken);
    }

    static setRefreshToken(token: string) {
        localStorage.setItem(LocalStorageKeys.refreshToken, token);
    }

    static isAuthenticated(): boolean {
        const token = localStorage.getItem(LocalStorageKeys.token);
        return Boolean(token);
    }

    static setKeepMeLoggedIn(selected: boolean) {
        localStorage.setItem(LocalStorageKeys.keepMeLoggedIn, selected ? 'true' : 'false');
    }

    static getKeepMeLoggedIn() {
        const res = localStorage.getItem(LocalStorageKeys.keepMeLoggedIn);
        return Boolean(res);
    }

    static logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    static getUsername() {
        const res = localStorage.getItem(LocalStorageKeys.username);
        return res ? res : "";
    }

    static setUsername(username: string) {
        localStorage.setItem(LocalStorageKeys.username, username);
    }
}