import { Navigate, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';

import { AuthService } from '../core/services/authentication';

const AuthNavigator = React.lazy(() => import('./auth/auth-navigator'));
const RoomNavigator = React.lazy(() => import('./room/room-navigator'));
const DashboardNavigator = React.lazy(() => import('./dashboards/dashboard-navigator'));

interface Props { }


const Navigator: React.FC<Props> = (props: Props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/' element={AuthService.isAuthenticated() ? <Navigate to={'/' + AuthService.getUsername()} /> : <Navigate to="/account/login" />} />
        <Route path='account/*' element={<AuthNavigator />} />
        <Route path='room/*' element={<RoomNavigator />} />
        <Route path=':username/*' element={<DashboardNavigator />} />
      </Routes>
    </Suspense>
  );
}

export default Navigator;
