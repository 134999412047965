export const CHANGE_THEME = `CHANGE_THEME`;
export const REFRESH_TOKEN = `REFRESH_TOKEN`;
export const REFRESH_TOKEN_SUCCESS = `REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_ERROR = `REFRESH_TOKEN_ERROR`;
export const REDIRECT = `REDIRECT`;

export function changeTheme(themeName: string) {
    return {
        type: CHANGE_THEME,
        payload: {
            themeName
        }
    };
}

export function refreshToken(refreshToken: string) {
    return {
        type: REFRESH_TOKEN,
        payload: {
            refresh: refreshToken,
        }
    };
}

export function refreshTokenSuccess(response: any) {
    return {
        type: REFRESH_TOKEN_SUCCESS,
        payload: response
    };
}

export function refreshTokenError(error: any) {
    return {
        type: REFRESH_TOKEN_ERROR,
        payload: error
    };
}

export function redirectTo(path: string) {
    return {
        type: REDIRECT,
        payload: path
    };
}