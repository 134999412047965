import { HIDE_LOADING, REDIRECT_TO, RESET_STATE, SET_PASSWORD, SET_USERNAME, SHOW_LOADING } from './actions';

export interface AuthReducerState {
  loading: boolean;
  username: string;
  password: string;
  redirectTo: string;
}

const init: AuthReducerState = {
  loading: false,
  username: '',
  password: '',
  redirectTo: '',
};

export function authReducer(state = init, action: any): AuthReducerState {
  switch (action.type) {
    case SHOW_LOADING: return { ...state, loading: true };
    case HIDE_LOADING: return { ...state, loading: false };
    case SET_USERNAME: return { ...state, username: action.payload };
    case SET_PASSWORD: return { ...state, password: action.payload };
    case REDIRECT_TO: return { ...state, redirectTo: action.payload };
    case RESET_STATE: return { ...init };
    default: return state;
  }
}