import { Room } from "../../../core/models/room";

const namespace = '[user]';

export const LOAD_USER = `${namespace} Load user`;
export const SHOW_LOADING = `${namespace} Show loading`;
export const HIDE_LOADING = `${namespace} Hide loading`;
export const RESET_STATE = `${namespace} Reset user state`;
export const LOAD_USER_SUCCESS = `${namespace} Lodaing user succeed`

export function showLoading() {
    return {
        type: SHOW_LOADING
    };
}

export const loadUserInfo = () => ({
    type: LOAD_USER,
});


export function hideLoading() {
    return {
        type: HIDE_LOADING
    };
}

export function loadUserSuccess(userAccess: Room[]) {
    return {
        type: LOAD_USER_SUCCESS,
        payload: userAccess
    }
}

export function resetState() {
    return {
        type: RESET_STATE
    };
}
