import { HIDE_LOADING, LOAD_ROOM_SUCCESS, SHOW_LOADING } from './actions';
import { Room } from '../../../core/models/room';

export interface RoomReducerState {
  loading: boolean;
  room: Room;
}

const init: RoomReducerState = {
  loading: false,
  room: null as any,
};

export function roomReducer(state = init, action: any): RoomReducerState {
  switch (action.type) {
    case SHOW_LOADING: return { ...state, loading: true };
    case HIDE_LOADING: return { ...state, loading: false };
    case LOAD_ROOM_SUCCESS: return { ...state, room: action.payload };
    default: return state;
  }
}