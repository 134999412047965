import { CHANGE_THEME, REDIRECT } from './actions';

export interface AppReducerState {
  themeName: 'dark' | 'light';
  redirectTo: string;
}

const init: AppReducerState = {
  themeName: 'dark',
  redirectTo: '',
};

export function appReducer(state = init, action: any): AppReducerState {
  switch (action.type) {
    case CHANGE_THEME: return { ...state, themeName: action.payload.themeName };
    case REDIRECT: return { ...state, redirectTo: action.payload };
    default: return state;
  }
}