import { Room } from '../../../core/models/room';

const namespace = '[room]';

export const SHOW_LOADING = `${namespace} Show loading`;
export const HIDE_LOADING = `${namespace} Hide loading`;
export const LOAD_ROOM = `${namespace} Load room`;
export const LOAD_ROOM_SUCCESS = `${namespace} Load room success`;

export const showLoading = () => ({
    type: SHOW_LOADING
});

export const hideLoading = () => ({
    type: HIDE_LOADING
});

export const loadRoom = (id: number) => ({
    type: LOAD_ROOM,
    payload: id,
});

export const loadRoomSuccess = (room: Room) => ({
    type: LOAD_ROOM_SUCCESS,
    payload: room,
});
